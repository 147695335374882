<template>
  <div>
    <v-row>
      <v-col
        cols="4"
        lg="3"
        class="mx-1"
      >
        <p class="text-h5 font-weight-medium font-italic mb-0">
          {{ displayedRole }}
        </p>
      </v-col>
      <v-col
        v-if="showControls"
        cols="4"
        lg="2"
        sm="3"
        class="mx-1"
      >
        <v-select
          v-model="subscriptionPackage"
          :items="displayedPackageSelect"
          :loading="isLoading()"
          label="Package"
          outlined
          dense
          full-width
          hide-details
          color="primary"
          @change="getRoleClaims"
        />
      </v-col>
      <v-col
        v-if="showControls"
        cols="4"
        lg="2"
        sm="3"
        class="mx-1"
      >
        <v-select
          v-model="role"
          :items="roleSelect"
          :loading="isLoading()"
          label="Role"
          outlined
          dense
          full-width
          hide-details
          color="primary"
          @change="getRoleClaims"
        />
      </v-col>
    </v-row>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-h6 text-left">
              Category
            </th>
            <th class="text-h6 text-left">
              Features
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="category in displayedCategories"
            :key="category.code"
          >
            <td>
              <v-chip
                class="black--text"
                :color="category.color"
              >
                {{ category.title }}
              </v-chip>
            </td>
            <td>
              <v-chip
                v-for="feature in category.features"
                :key="feature.code"
                class="ma-1 black--text"
                :color="roleHasClaim(category.code, feature.code) ? category.color : unauthColor"
              >
                {{ feature.title }}
              </v-chip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
// import {
//   SET_LOADING_MESSAGE
// } from "@/store/mutation-types";
import { authService } from '@/shared/services'
import { categoryFeatures, packages, roles } from '@/shared/models/auth'
import { mapGetters, mapMutations } from 'vuex'
import claims from '@/shared/models/auth/claims'

export default {
  name: 'PermissionsOverview',

  data: () => ({
    unauthColor: 'grey lighten-2',
    claims: null,
    all: categoryFeatures,
    subscriptionPackage: null,
    packageSelect: packages,
    role: null,
    roleSelect: roles
  }),

  computed: {
    ...mapGetters('auth', [
      'hasClaimKV',
      'isMMSRole',
      'getClaims',
      'getRole',
      'getRolePackageCode',
      'getRoleTitle'
    ]),
    ...mapGetters(['isLoading']),
    computedClaims: {
      get: function () {
        return this.getClaims()
      }
    },
    computedRole: {
      get: function () {
        return this.getRoleTitle()
      }
    },
    computedPackageCode: {
      get: function () {
        return this.getRolePackageCode()
      }
    },
    showMMSCategories () {
      return this.subscriptionPackage && this.subscriptionPackage === 'MMS'
    },
    showControls () {
      return this.hasClaimKV(claims.AD_UM) || process.env.VUE_APP_IS_DEBUG === 'true'
    },
    displayedPackageSelect () {
      let packs = this.packageSelect
      return this.isMMSRole() ? packs : packs.filter(x => x.value !== 'MMS')
    },
    displayedCategories () {
      return this.showMMSCategories ? this.all : this.all.filter(x => x.code !== 'MMS')
    },
    displayedRole () {
      return this.subscriptionPackage && this.role
        ? `${this.subscriptionPackage}-${this.role}`
        : ''
    }
  },

  watch: {
    computedClaims: {
      immediate: true,
      handler (newValue, oldValue) {
        this.claims = newValue
      }
    },
    computedRole: {
      immediate: true,
      handler (newValue, oldValue) {
        this.role = newValue
      }
    },
    computedPackageCode: {
      immediate: true,
      handler (newValue, oldValue) {
        this.subscriptionPackage = newValue
      }
    }
  },

  methods: {
    // ...mapMutations([SET_LOADING_MESSAGE]),
    async getRoleClaims () {
      // this.setLoadingMessage("random");
      let data = await authService.getRoleClaims(this.displayedRole)
      if (data.errorResponse) {
        this.$store.commit('setGlobalSnackbar', {
          message: data.errorResponse,
          color: 'error'
        })
        return
      }
      this.claims = data
    },
    roleHasClaim (key, val) {
      if (!this.claims) return this.claims
      return this.claims.some(
        claim => claim.Key === key && claim.Value === val
      )
    }
  }
}
</script>
