var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c("v-col", { staticClass: "mx-1", attrs: { cols: "4", lg: "3" } }, [
            _c(
              "p",
              { staticClass: "text-h5 font-weight-medium font-italic mb-0" },
              [_vm._v("\n        " + _vm._s(_vm.displayedRole) + "\n      ")]
            )
          ]),
          _vm.showControls
            ? _c(
                "v-col",
                { staticClass: "mx-1", attrs: { cols: "4", lg: "2", sm: "3" } },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.displayedPackageSelect,
                      loading: _vm.isLoading(),
                      label: "Package",
                      outlined: "",
                      dense: "",
                      "full-width": "",
                      "hide-details": "",
                      color: "primary"
                    },
                    on: { change: _vm.getRoleClaims },
                    model: {
                      value: _vm.subscriptionPackage,
                      callback: function($$v) {
                        _vm.subscriptionPackage = $$v
                      },
                      expression: "subscriptionPackage"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.showControls
            ? _c(
                "v-col",
                { staticClass: "mx-1", attrs: { cols: "4", lg: "2", sm: "3" } },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.roleSelect,
                      loading: _vm.isLoading(),
                      label: "Role",
                      outlined: "",
                      dense: "",
                      "full-width": "",
                      "hide-details": "",
                      color: "primary"
                    },
                    on: { change: _vm.getRoleClaims },
                    model: {
                      value: _vm.role,
                      callback: function($$v) {
                        _vm.role = $$v
                      },
                      expression: "role"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("v-simple-table", {
        attrs: { dense: "" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c("thead", [
                  _c("tr", [
                    _c("th", { staticClass: "text-h6 text-left" }, [
                      _vm._v("\n            Category\n          ")
                    ]),
                    _c("th", { staticClass: "text-h6 text-left" }, [
                      _vm._v("\n            Features\n          ")
                    ])
                  ])
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.displayedCategories, function(category) {
                    return _c("tr", { key: category.code }, [
                      _c(
                        "td",
                        [
                          _c(
                            "v-chip",
                            {
                              staticClass: "black--text",
                              attrs: { color: category.color }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(category.title) +
                                  "\n            "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "td",
                        _vm._l(category.features, function(feature) {
                          return _c(
                            "v-chip",
                            {
                              key: feature.code,
                              staticClass: "ma-1 black--text",
                              attrs: {
                                color: _vm.roleHasClaim(
                                  category.code,
                                  feature.code
                                )
                                  ? category.color
                                  : _vm.unauthColor
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(feature.title) +
                                  "\n            "
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ])
                  }),
                  0
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }